<template>
  <auth-card btn-classes="full-width">
    <template #description>
      <el-form ref="mfaTokenForm"
        :model="mfaTokenForm"
        :rules="mfaTokenRules"
        label-position="left"
        label-width="60px"
        @submit.native.prevent="verify">
        <fieldset class="auth-fieldset text-align-left">
          <legend>{{ $t('authentication.mfa-long') }}</legend>

          <p>{{ $t('authentication.mfa-enter-token') }}</p>

          <el-form-item :label="$t('general.token')"
            prop="oneTimePassword">
            <el-input v-model="mfaTokenForm.oneTimePassword"
              autofocus
              data-testid="mfa-form-token-input"
              name="oneTimePassword" />
          </el-form-item>

          <el-button name="verify"
            type="primary"
            class="full-width mt-2"
            data-testid="mfa-form-verify-button"
            @click="verify">
            {{ $t('authentication.verify') }}
          </el-button>

          <h5 class="or">
            <span>{{ $t('authentication.or') }}</span>
          </h5>

          <el-button name="back"
            class="full-width"
            data-testid="mfa-form-back-button"
            @click="$router.push('/')">
            {{ $t('general.back') }}
          </el-button>
        </fieldset>
      </el-form>
    </template>
  </auth-card>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { validateForm } from '@/utils/form.js'
import AuthCard from '@/components/ui-components/AuthCard.vue'
export default {
  name: 'MfaForm',

  components: { 'auth-card': AuthCard },

  data() {
    return {
      loader: null,
      mfaTokenForm: {
        oneTimePassword: '',
      },
      mfaTokenRules: {
        oneTimePassword: { required: true, validator: this.validateOneTimePassword, trigger: 'blur' },
      },
    }
  },

  computed: {
    ...mapGetters('Auth', ['hasCompany', 'hasFeatureFlag', 'requiresMfaSetup', 'homePage']),
    ...mapState('Auth', ['userLoginPayload']),
  },

  methods: {
    ...mapActions('Auth', ['login', 'logout']),
    ...mapMutations('Auth', ['setLoggingIn']),
    ...mapActions('Services', ['getMyServices']),
    ...mapMutations('Notifications', ['notifyBad']),

    validateOneTimePassword(_rule, value, callback) {
      if (value.length === 0) {
        callback(this.$t('validations.mfa-token'))
      } else {
        callback()
      }
    },

    async verify() {
      try {
        await validateForm(this.$refs.mfaTokenForm)
      } catch {
        return /* error displays inline if this fails */
      }

      const payload = {
        ...this.userLoginPayload,
        oneTimePassword: this.mfaTokenForm.oneTimePassword,
      }

      try {
        this.setLoggingIn(true)

        this.loader = this.$loading({
          lock: true,
          text: this.$t('authentication.mfa-verify-token'),
          background: 'rgba(255, 255, 255, 0.95)',
        })

        await this.login({ payload })

        if (this.requiresMfaSetup) {
          this.$router.push('/setup-mfa')
          return
        }

        if (!this.hasCompany) {
          this.$router.push({ name: 'CompanyTradingName' })
          return
        }

        // Load services then redirect to home page
        this.loader.setText(this.$t('services.loading-services'))
        await this.getMyServices()

        this.$router.push(this.homePage)
      } catch (error) {
        let errorMessage = error.data?.message

        if (error.data.data === 'RP') {
          this.$router.push({ path: '/login/username', query: { passwordReset: true } })
          return
        } else if (error.data.data === '2FA') {
          errorMessage = this.$t('authentication.mfa-token-invalid', { code: this.mfaTokenForm.oneTimePassword })
        }

        this.notifyBad({ title: errorMessage, duration: 8000 })
      } finally {
        this.setLoggingIn(false)
        this.loader.close()
      }
    },
  },
}

</script>

<style scoped>
/* some styling interfering with this elsewhere, so override it locally */
.el-button + .el-button {
  margin-left: 0;
}
</style>
